<template>
  <div v-if="items && items.length" class="product-gallery">
    <ProductParamSlider
      v-if="items.length >= 2"
      v-slot="{ item }"
      :items="items"
      :slider-options="sliderOptions"
    >
      <label class="product__color" :class="{ 'product__color--active': active === item.id }">
        <input type="radio" v-show="false" :value="item.id" v-model="active" />
        <img
          loading="lazy"
          decoding="async"
          :src="item | image($store.state.api)"
          :alt="item | image_alt($store.state.api)"
        />
      </label>
    </ProductParamSlider>
    <a :href="activeItem | image($store.state.api)" class="product-gallery__image car-page__img">
      <img
        loading="lazy"
        decoding="async"
        :src="activeItem | image($store.state.api)"
        :alt="activeItem | image_alt($store.state.api)"
      />
    </a>
  </div>
</template>

<script>
import ProductParamSlider from "./ProductParamSlider.vue";

export default {
  name: "ProductGalleryComponent",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    head_img: Object,
    isModal: Boolean,
  },
  data() {
    return {
      active: null,
      sliderOptions: {
        direction: "horizontal",
        slidesPerView: "auto",
        spaceBetween: 4,
        pagination: false,
        clickable: false,
        breakpoints: {
          860: {
            direction: "vertical",
            slidesPerView: 5,
          },
        },
      },
    };
  },
  computed: {
    activeItem() {
      return this.items.find((i) => i.id === this.active) || this.head_img;
    },
  },
  created() {
    if (this.items && this.items.length) {
      this.active = this.items[0].id;
    }
  },
  watch: {
    items() {
      if (this.items && this.items.length) {
        this.active = this.items[0].id;
      }
    },
  },
  components: { ProductParamSlider },
};
</script>

<style lang="stylus">
.product-gallery {
  margin 20px
  display flex
  align-items flex-start
  gap 24px
  flex-basis: calc(60% - (20px * 2))
  +below(860px) {
    gap 0
    flex-direction column-reverse
    flex-basis: calc(100% - (20px * 2))
  }

  .param-slider {
    width 110px
    height 550px
    flex-shrink: 0;
    +below(860px) {
      width 100%
      height 120px
    }
  }

  &__image {
    height auto
    max-width 100%
    width 100%
    border-radius: 8px
    display flex
    justify-content center
    align-items center
    overflow hidden
    margin-bottom 14px

    img {
      width 100%
      height 100%
      object-fit contain
      object-position center
    }
  }
}

.product {
  &__color {
    cursor pointer
    border: 2px solid transparent;
    border-radius 8px
    height 90px
    width 120px
    overflow hidden
    transition 0.2s

    &--active {
      border-color orange
    }

    &--small {
      +below(860px) {
        height 86px
      }
    }

    img {
      width 100%
      height 100%
      object-fit cover
      object-position center
    }
  }

  &__color-slider {
    display flex
    flex-direction column
    width: 100%;
    gap 12px
    align-items flex-start
    justify-content flex-start
  }
}
</style>
