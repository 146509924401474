<template>
  <main class="main">
    <section class="container" v-if="car">
      <div class="breadcrumb">
        <ul class="breadcrumb__list">
          <li v-if="$route.name !== 'home'">
            <router-link :to="{ name: 'home' }">Главная</router-link>
          </li>
          <template>
            <li v-for="(parent, index) in parentLinks" :key="index">
              <router-link :to="{ name: parent.name, params: parent.params || {} }"
                >{{ parent.title }}
              </router-link>
            </li>
          </template>
          <li v-if="car.title">
            <span>{{ car.title }}</span>
          </li>
        </ul>
        <h1 class="breadcrumb__title">{{ car.title }}</h1>
      </div>
      <div class="car-page" :class="{ 'car-page--with-gallery': carImages.length >= 2 }">
        <ProductGalleryComponent :items="carImages" class="car-page__gallery" />
        <div class="car-page__content">
          <h2 class="car-page__title">{{ car.title }}</h2>
          <span class="car-page__red" v-if="car.share">{{ car.share.title }}</span>
          <div class="car-page__prices">
            <div class="car-page__item" v-if="car.full_price">
              <span class="car-page__item__title">Цена:</span>
              <div class="car-page__item__content">
                <div class="car-page__item-price">от {{ car.full_price.toLocaleString("ru-RU") }} ₽</div>
              </div>
            </div>
            <div class="car-page__item" v-else-if="car.price">
              <span class="car-page__item__title">Цена:</span>
              <div class="car-page__item__content">
                <div class="car-page__item-price">{{ car.price.toLocaleString("ru-RU") }} ₽</div>
              </div>
            </div>
            <div class="car-page__item" v-if="car.price_per_month">
              <span class="car-page__item-type">в месяц</span>
              <span class="car-page__item-price car-page__item-price--small">
                от {{ car.price_per_month.toLocaleString("ru-RU") }} ₽
              </span>
            </div>
          </div>
          <div v-if="car.tax_savings_price" class="car-page__item-wrap">
            <SuccessIcon />
            <div class="car-page__item car-page__item--bright">
              <span class="car-page__item-price car-page__item-price--small">
                до {{ car.tax_savings_price.toLocaleString("ru-RU") }} ₽
              </span>
              <span class="car-page__item-type">налоговая экономия</span>
            </div>
            <QuestionIcon
              content="Расчет является примерным, точная сумма ежемесячного платежа рассчитывается при формировании коммерческого предложения менеджером Лизинг-Трейд."
              v-tippy="{ followCursor: false, placement: 'top' }"
            />
          </div>
          <div class="car-page__item" v-if="car.color">
            <span class="car-page__item__title">Цвет:</span>
            <div class="car-page__item__content">
              <div class="color">
                <div class="color__preview" :style="'background: ' + car.color.color"></div>
                <span class="color__name">{{ car.color.title }}</span>
              </div>
            </div>
          </div>
          <div class="car-page__item" v-if="car.description">
            <span class="car-page__item__title">Опции:</span>
            <div class="car-page__item__content" v-html="car.description"></div>
          </div>
        </div>
      </div>
    </section>
    <CalculatorComponent
      :vehicles="vehicles"
      :price="car && car.price ? car.price : 0"
      :active-vehicle="car && car.vehicle ? car.vehicle : null"
    />
    <section class="container" v-if="products.length">
      <h2 class="prg__tabs__title">Смотрите также</h2>
      <div class="car-page__list" id="flickity_cars" :class="{ 'flickity-ready': flickityReady }">
        <router-link
          :to="{ name: 'car', params: { brand: product.brand.link, url: product.link } }"
          class="car"
          v-for="(product, index) in products"
          :key="index"
        >
          <h2 class="car__title">{{ product.title }}</h2>
          <div class="car__img__block">
            <span
              class="car__red-mark"
              v-if="product.share"
              :content="product.share.title"
              v-tippy="{ followCursor: false, placement: 'top', theme: 'car__tooltip' }"
            ></span>
            <img
              loading="lazy"
              decoding="async"
              :src="getImg(product)"
              :alt="getImgAlt(product)"
              class="car__img"
            />
          </div>
          <div class="car__prices">
            <div class="car__price__item" v-if="product.full_price">
              <div class="car__price__col">
                <span class="car__price">от {{ product.full_price.toLocaleString("ru-RU") }} ₽</span>
                <span class="car__price__type">цена автомобиля</span>
              </div>
            </div>
            <div class="car__price__item" v-if="product.price_per_month">
              <div class="car__price__col">
                <span class="car__price">от {{ product.price_per_month.toLocaleString("ru-RU") }} ₽</span>
                <span class="car__price__type">в месяц</span>
              </div>
            </div>
            <div class="car__price__item car__price__item--bright" v-if="product.tax_savings_price">
              <div class="car__price__check">
                <SuccessIcon />
              </div>
              <div class="car__price__col">
                <span class="car__price">
                  <span>от {{ product.tax_savings_price.toLocaleString("ru-RU") }} ₽</span>
                  <!--                      <QuestionIcon v-tooltip="{ content: 'Текст', classes: 'adv__tooltip', placement: 'top' }"/>-->
                </span>
                <span class="car__price__type">в месяц</span>
              </div>
            </div>
          </div>
          <button type="button" class="btn-dark">Открыть</button>
        </router-link>
      </div>
    </section>
  </main>
</template>

<script>
import SuccessIcon from "components/svg/Success.vue";
import CalculatorComponent from "components/Calculator.vue";
import ProductGalleryComponent from "./components/ProductGalleryComponent.vue";
import QuestionIcon from "components/svg/Question.vue";

export default {
  name: "CarPage",
  async asyncData({ store, route, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_CAR_PAGE", route.params.url);
  },
  beforeCreate() {
    if (!this.$route.meta.parent.filter((p) => p.name === "car").length) {
      this.$route.meta.parent.push({
        title: this.$store.state.car_page.product.brand.title,
        name: "brand",
        params: {
          brand: this.$route.params.brand,
        },
      });
    }
  },
  data() {
    return {
      flickityReady: false,
      flickity: undefined,
    };
  },
  computed: {
    parentLinks() {
      return [
        {
          title: "Каталог",
          name: "catalog",
        },
        {
          title: this.brand.title,
          name: "brand",
          params: {
            url: this.brand.link,
          },
        },
      ];
    },
    brand() {
      if (this.car && this.car.brand) {
        return this.car.brand;
      } else {
        return [];
      }
    },
    vehicles() {
      if (this.$store.state.car_page && this.$store.state.car_page.vehicles) {
        return this.$store.state.car_page.vehicles;
      } else {
        return [];
      }
    },
    car() {
      if (this.$store.state.car_page && this.$store.state.car_page.product) {
        return this.$store.state.car_page.product;
      } else {
        return undefined;
      }
    },
    products() {
      if (this.$store.state.car_page && this.$store.state.car_page.products) {
        return this.$store.state.car_page.products.filter((p) => p.brand);
      } else {
        return [];
      }
    },
    carImages() {
      let images = [];
      if (this.car.head_img) {
        images.push(this.car.head_img);
      }
      if (this.car.slider_img && this.car.slider_img.length) {
        images.push(...this.car.slider_img);
      }
      return images;
    },
  },
  mounted() {
    if (document.getElementById("flickity_cars")) {
      const Flickity = require("flickity");
      let cellsGroup = 4;
      if (this.$mq === "560" || this.$mq === "768" || this.$mq === "900") {
        cellsGroup = false;
      } else if (this.$mq === "1097") {
        cellsGroup = 3;
      } else if (this.$mq === "lg") {
        cellsGroup = 4;
      }
      this.flickity = new Flickity("#flickity_cars", {
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        contain: true,
        groupCells: cellsGroup,
        on: {
          ready: () => {
            this.flickityReady = true;
          },
        },
      });
    }
  },
  /**
   * Чтобы очистить загруженную информацию для устранения
   * непредвиденных последствий
   */
  beforeRouteLeave(to, from, next) {
    this.$store.state.car_page = {};
    next();
  },
  methods: {
    getImg(product) {
      if (product.head_img && product.head_img.img && product.head_img.img.url) {
        return this.$store.state.api + product.head_img.img.url;
      }
    },
    getImgAlt(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.alt) {
        return item.head_img.img.alt;
      }
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let alternateLink = this.$store.state.alternateLink;
    let data = {};
    if (this.$store.state.car_page && this.$store.state.car_page.product) {
      data = this.$store.state.car_page.product;
    }
    const title = data.meta_title || data.title;

    let metaFullPath = this.$route.fullPath;
    let parentMeta = this.$route.meta.parent.map((item, i) => {
      return {
        "@type": "ListItem",
        position: i + 2,
        name: item.title,
        item:
          api +
          "/" +
          this.$router.resolve({
            name: item.name,
            params: item.params,
          }).href,
      };
    });

    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: data.meta_description || "",
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              ...parentMeta,
              {
                "@type": "ListItem",
                position: "4",
                name: title,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: data.meta_keywords || "",
        },
        {
          vmid: "description",
          name: "description",
          content: data.meta_description || "",
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: data.meta_description || "",
        },
      ],
    };
  },
  components: {
    QuestionIcon,
    ProductGalleryComponent,
    CalculatorComponent,
    SuccessIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/flickity.styl"
@import "~@/styles/parts/car.styl"
@import "~@/styles/parts/breadcrumbs.styl"
</style>
